export default {
    root: ({ props, context, parent }) => {
        return ({
            class: [
                'inline-block relative',
    
                // Base Label Appearance
                '[&>*:last-child]:text-surface-900/60 dark:[&>*:last-child]:text-white/60',
                '[&>*:last-child]:absolute',
                '[&>*:last-child]:top-1/2',
               
                '[&>*:last-child]:-translate-y-1/2',
                '[&>*:last-child]:left-4',
                {
                    '![&>*:last-child]:left-14': parent.props.iconPosition === 'left',
                },
                '[&>*:last-child]:pointer-events-none',
                '[&>*:last-child]:transition-all',
                '[&>*:last-child]:duration-200',
                '[&>*:last-child]:ease',
    
                // Focus Label Appearance
                '[&>*:last-child]:has-[:focus]:top-4',
                '[&>*:last-child]:has-[:focus]:text-sm',
                '[&>*:last-child]:has-[:focus]:z-10',
                {
                    '[&>*:last-child]:has-[:focus]:left-14': parent.props.iconPosition === 'left',
                },
    
                // Filled Input Label Appearance
                '[&>*:last-child]:has-[.filled]:top-4',
                '[&>*:last-child]:has-[.filled]:text-sm',
                '[&>*:last-child]:has-[.filled]:z-10',
                {
                    '[&>*:last-child]:has-[.filled]:left-14': parent.props.iconPosition === 'left',
                },
            ]
        })
    }
};
