import * as Sentry from '@sentry/vue';
import { router } from 'app/providers/router/router';
import { SENTRY_DSN } from 'shared/config';
import type { App } from 'vue';

export function setupSentry(app: App) {
  const mode = import.meta.env.MODE;
  if (mode === 'staging' || mode === 'production') {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.httpClientIntegration({ failedRequestStatusCodes: [[400, 499], [500, 599]] }),
        Sentry.httpContextIntegration(),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      trackComponents: true, // give Sentry tracking all Vue components, can take []with comp name
      hooks: ['mount', 'update', 'unmount'], // Control which lifecycle hooks should be tracked.
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      tracePropagationTargets: ['https://test-ocenter.zeon.mdev.kz/', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}
