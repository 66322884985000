import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: 'admin',
  redirect: { name: PageName.ADMIN_CITY },
  children: [
    {
      path: 'city',
      name: PageName.ADMIN_CITY,
      component: () => import('modules/admin/pages/AdminCity.vue'),
    },
    {
      path: 'delivery',
      name: PageName.ADMIN_DELIVERY,
      component: () => import('modules/admin/pages/AdminDelivery.vue'),
    },
  ],
};

export default (router: Router) => {
  router.addRoute(PageName.MAIN_LAYOUT, moduleRoute);
};
