import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: 'orders',
  name: PageName.ORDERS_PAGE,
  component: () => import('./pages/OrdersIndex.vue'),
  redirect: { name: 'AllOrders' },
  meta: {
    breadcrumbName: 'Все заказы',
  },
  children: [
    {
      path: ':uid',
      name: PageName.ALL_ORDERS,
      component: () => import('./pages/AllOrdersPage.vue'),
      meta: {
        // saveQueryFor сохраняет квери маршрута при навигации по BreadCrumbs для того, что укажешь здесь.
        // вычисления в src/app/providers/router/router.ts
        saveQueryFor: [
          {
            targetPageName: PageName.ALL_ORDERS, // целевой маршрут
            targetQueryKeys: ['personType'], // ключи query для сохранения
          },
          {
            targetPageName: PageName.BASKET,
            targetQueryKeys: ['personType'],
          },
          {
            targetPageName: PageName.ORDER_DETAILS,
            targetQueryKeys: ['personType'],
          },
        ],
      },
    },
    {
      path: ':uid/detail/:orderId',
      name: PageName.ORDER_DETAILS,
      component: () => import('./pages/OrderDetailPage.vue'),
      meta: {
        breadcrumbName: 'Заказ №', // dynamically added N+orderId in OrderIndex.vue
        saveQueryFor: [
          {
            targetPageName: PageName.ALL_ORDERS,
            targetQueryKeys: ['personType', 'ids'],
          },
        ],
      },
    },
    {
      path: ':uid/basket',
      name: PageName.BASKET,
      component: () => import('./pages/BasketPage.vue'),
      meta: {
        breadcrumbName: 'Корзина',
        saveQueryFor: [
          {
            targetPageName: PageName.ALL_ORDERS,
            targetQueryKeys: ['personType'],
          },
        ],
      },
    },
    {
      path: ':uid/bonuses',
      name: PageName.BONUSES,
      component: () => import('./pages/BonusDetailsPage.vue'),
      meta: {
        breadcrumbName: 'Бонусы',
        saveQueryFor: [
          {
            targetPageName: PageName.ALL_ORDERS,
            targetQueryKeys: ['personType'],
          },
        ],
      },
    },
  ],
};

export default (router: Router) => {
  router.addRoute(PageName.MAIN_LAYOUT, moduleRoute);
};
