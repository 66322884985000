import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: '',
  name: PageName.LOGIN_PAGE,
  component: () => import('modules/login/pages/LoginIndex.vue'),
  children: [],
};

export default (router: Router) => {
  router.addRoute(PageName.LOGIN_LAYOUT, moduleRoute);
};
