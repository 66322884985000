export enum PageName {
  LOGIN_PAGE = 'LoginPage',
  LOGIN_LAYOUT = 'LoginLayout',
  MAIN_LAYOUT = 'MainLayout',
  ADMIN_PAGE = 'AdminPage',
  ADMIN_CITY = 'AdminCity',
  ADMIN_DELIVERY = 'AdminDelivery',
  ORDERS_PAGE = 'OrdersPage',
  ASSIGN_PAGE = 'AssignPage',
  ALL_ORDERS = 'AllOrders',
  ORDER_DETAILS = 'OrderDetail',
  ALL_ASSIGNS = 'AllAssigns',
  MY_ASSIGNS = 'MyAssigns',
  BASKET = 'Basket',
  BONUSES = 'Bonuses',
  ERROR_404 = 'Error_404',
  ERROR_500 = 'Error_500',
  ERROR_403 = 'Error_403',
}
