export default {
  root: ({ props, context, parent }) => {
    return ({
      class: [
        // Font
        'text-base',
        'leading-5',
        'w-full',

        // Flex
        { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },

        // Spacing
        'm-0',
        {
          'py-3 px-3.5': props.size == 'large',
          'py-1.5 px-2': props.size == 'small',
          'py-2 px-4': props.size == null,
          'pt-[29px] pb-[7px]': props.size == null && parent.instance?.$name === 'FloatLabel',
          'py-4.5': props.size == null && parent.instance?.$name !== 'FloatLabel',
        },

        // Shape
        { 'rounded-xl': parent.instance.$name !== 'InputGroup' },
        { 'first:rounded-l-md rounded-none last:rounded-r-md': parent.instance.$name == 'InputGroup' },
        { 'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup' },
        { 'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons },

        // Colors
        'text-surface-800 dark:text-white/80',
        'placeholder:text-surface-400 dark:placeholder:text-surface-500',
        { 'bg-surface-0 dark:bg-surface-950': !context.disabled },
        'border',
        { 'border-surface-300 dark:border-surface-700': !props.invalid },

        // Invalid State
        'invalid:focus:ring-red-200',
        'invalid:hover:border-red-500',
        { 'border-red-500 dark:border-red-400': props.invalid },

        // States
        {
          'hover:border-primary': !context.disabled && !props.invalid,
          'focus:(outline-none outline-offset-0 ring-1 ring-primary-500 z-10) dark:focus:ring-primary-400': !context.disabled,
          'bg-surface-200 dark:bg-surface-700 select-none pointer-events-none cursor-default': context.disabled,
        },

        // Filled State *for FloatLabel
        { filled: parent.instance?.$name == 'FloatLabel' && context.filled },

        // Misc
        'appearance-none',
      ],
    })
  },
};
