import type { IntlNumberFormat } from 'vue-i18n';

export const numberConfig = Object.freeze({
  currency: {
    style: 'currency',
    currency: 'KZT',
    notation: 'standard',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  },
  percent: {
    style: 'percent', useGrouping: false,
  },
  decimal: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
}) as IntlNumberFormat;
