import type { IntlDateTimeFormat } from 'vue-i18n';

export const dateTimeConfig = Object.freeze({
  onlyTime: {
    hour: 'numeric',
    minute: 'numeric',
  },
  veryShort: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
}) as IntlDateTimeFormat;
