export function setCookie(name: string, value: string, hours: number) {
  let expires = '';
  if (hours) {
    const date = new Date();

    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function deleteCookie(cookieName: string) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function getTokenCookie(): string {
  let token = getCookies('token');

  if (/[а-яё]/i.test(token)) {
    token = '';
    return token;
  } else {
    return token;
  }
}

export function getCookies(cookieName: string): string {
  return document.cookie.split(';').reduce((cookieValue: any, currentCookie: string) => {
    const [name, value] = currentCookie.trim().split('=');

    if (name === cookieName)
      return decodeURIComponent(value);

    return cookieValue;
  }, null);
}
