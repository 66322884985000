import 'app/assets/main.css';

import Ripple from 'primevue/ripple';

import { createApp } from 'vue';
import 'virtual:uno.css';
import '@unocss/reset/tailwind-compat.css';
import 'mechtaui/dist/style.css';

import { setupI18n } from 'i18n/index';
import PrimeVue from 'primevue/config';
import CrmDesktopPreset from 'shared/ui-crm-preset';
import { setupSentry } from 'app/plugins/sentry';
import { vMaska } from 'maska';
import { plugin as vueTransitionsPlugin } from '@morev/vue-transitions';
import App from './App.vue';
import { registerModules } from './providers/register-modules';
import { store } from './providers/store';
import { router } from './providers/router/router';
import '@morev/vue-transitions/styles';

export async function initialize() {
  const app = createApp(App);
  app.use(store);
  app.use(PrimeVue, {
    unstyled: true,
    pt: CrmDesktopPreset,
  });
  app.directive('ripple', Ripple);
  app.directive('maska', vMaska);
  registerModules(router);
  await setupI18n(app);
  app.use(router);
  app.use(vueTransitionsPlugin({}));
  setupSentry(app);
  app.mount('#app');
}
