export default {
  root: ({ props, parent, context }) => {
    return ({
      class: [
        // Flex
        'inline-flex',
        'relative',
        'w-full',
        { 'flex-col': props.showButtons && props.buttonLayout == 'vertical' },
        { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },
  
        // Shape
        { 'first:rounded-l-md rounded-none last:rounded-r-md': parent.instance.$name == 'InputGroup' && !props.showButtons },
        { 'border-0 border-y border-l last:border-r border-surface-300 dark:border-surface-700': parent.instance.$name == 'InputGroup' && !props.showButtons },
        { 'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons },
      ],
    })
  },
  input: {
    root: ({ parent, context }) => {
      return ({
        class: [
          // Text
          'text-base',
          { 'text-center': parent.props.showButtons && parent.props.buttonLayout == 'vertical' },

          // Font
          'leading-5',
  
          'w-full',
  
          // Display
          'flex flex-auto',
  
          // Spacing
          'py-2 px-4',
          {
            'pt-[29px] pb-[7px]': parent.instance?.$parentInstance?.$name === 'FloatLabel',
            'py-4.5': parent.instance?.$parentInstance?.$name !== 'FloatLabel',
          },
          'm-0',
  
          // Shape
          'rounded-xl',
          { 'rounded-l-none rounded-r-none': parent.props.showButtons && parent.props.buttonLayout == 'horizontal' },
          { 'rounded-none': parent.props.showButtons && parent.props.buttonLayout == 'vertical' },
  
          { 'border-0': parent.instance.$parentInstance?.$name == 'InputGroup' && !parent.props.showButtons },
  
          // Colors
          'text-surface-800 dark:text-white/80',
          'placeholder:text-surface-400 dark:placeholder:text-surface-500',
          { 'bg-surface-0 dark:bg-surface-950': !context.disabled },
          'border',
          { 'border-surface-300 dark:border-surface-700': !parent.props.invalid },
  
          // Invalid State
          'invalid:focus:ring-red-200',
          'invalid:hover:border-red-500',
          { 'border-red-500 dark:border-red-400': parent.props.invalid },
  
          // States
          { 'hover:border-primary': !parent.props.invalid },
          'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 focus:z-10',
          { 'bg-surface-200 dark:bg-surface-700 select-none pointer-events-none cursor-default': context.disabled },
  
          // Filled State *for FloatLabel
          { filled: parent.instance?.$parentInstance?.$name == 'FloatLabel' && parent.state.d_modelValue !== null },
  
          // Position
          { 'order-2': parent.props.buttonLayout == 'horizontal' || parent.props.buttonLayout == 'vertical' },
        ],
      })
    },
  },
};
